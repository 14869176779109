const { delay, first } = require("lodash");

document.onreadystatechange = () => {
    if (document.readyState === "complete") {
        $("#load").fadeOut(250);
    }
};

$(function () {
    function getCookie(cookieName) {
        const cookies = document.cookie.split(';');
        for (let i = 0; i < cookies.length; i++) {
            const cookie = cookies[i].trim();
            if (cookie.startsWith(cookieName + '=')) {
                return cookie.substring(cookieName.length + 1);
            }
        }
        return null; // Return null if the cookie is not found
    }

    async function setWhatsappNumber(lang) {
        try {
          const response = await fetch(`/getWhatsapp/${lang}`);
          const data = await response.json();
          const href = `https://api.whatsapp.com/send?phone=55${data.phone.replace(/\D/g, '')}&text=${data.message}`;
          document.getElementById("btn-whatsapp-desktop").href = href;
          document.getElementById("btn-whatsapp-mobile").href = href;
        } catch (error) {
          console.error(error);
        }
      }
      
      document.addEventListener("DOMContentLoaded", () => {
        setWhatsappNumber(lang);
      });


    function setImageBandeira(lang) {
        var image = '';

        switch (lang) {
            case 'pt':
                image = '/imgs/brazil.png';
                break;
            case 'en':
                image = '/imgs/england.png';
                break;
            case 'es':
                image = '/imgs/spain.png';
                break;

            default:
                break;
        }

        $('.btn-bandeira-lang').html('<img width="30px" src="https://' + window.location.host + image + '")}}">')
    }

    function translationLinks(lang) {
        var selectElements = document.getElementsByClassName("goog-te-combo");

        for (var j = 0; j < selectElements.length; j++) {
            var selectElement = selectElements[j];
            for (var i = 0; i < selectElement.options.length; i++) {
                if (selectElement.options[i].value === lang) {
                    // Define a propriedade selected como true para selecionar a opção
                    selectElement.options[i].selected = true;

                    // Dispara um evento change para simular uma mudança no valor selecionado
                    var event = new Event('change', {
                        bubbles: true,
                        cancelable: false,
                    });
                    selectElement.dispatchEvent(event);

                    // Sair do loop interno se quiser selecionar apenas a primeira opção correspondente
                    break;
                }
            }
        }

        setImageBandeira(lang);
        setWhatsappNumber(lang)
    }

    let firstLang = '';
    let langCookie = getCookie('googtrans') != null ? getCookie('googtrans') : '';
    let pathName = window.location.pathname.substring(1, 3);

    if (langCookie != null) {
        let $switch = '';

        if (langCookie != pathName) {
            $switch = '/auto/' + pathName;
        } else {
            $switch = langCookie;
        }

        console.log('switch: ', $switch)

        switch ($switch.trim()) {
            case '/auto/pt':
                firstLang = 'pt';
                break;
            case '/auto/en':
                firstLang = 'en';
                break;
            case '/auto/es':
                firstLang = 'es';
                break;
            default:
                firstLang = 'pt';
                break;
        }
    } else {
        firstLang = pathName;
    }

    console.log('lang:', firstLang, langCookie.substring(6, 8), pathName)

    if (firstLang == '') {
        firstLang = 'pt';
    }

    window.addEventListener('load', function () {
        if (firstLang != langCookie.substring(6, 8)) {
            setTimeout(function () {
                translationLinks(firstLang)
            }, 1000);
        } else {
            setImageBandeira(firstLang)
            setWhatsappNumber(firstLang)
        }

    })

    $('.translation-links').click(function (e) {
        e.preventDefault()

        var lang = $(this).data('lang');

        translationLinks(lang);

        let pathname = window.location.pathname;

        pathname = pathname.replace(pathname.substr(0, 3), '/' + lang);

        window.location.replace(pathname + window.location.search)

        // console.log(window.location);
        return;
    });

    $('.variacao').change(
        function () {
            if ($(this).val()) {
                $('#produtoPreco').html('<i class="fas fa-circle-notch fa-spin"></i>');
                $('#pesobruto').html('<i class="fas fa-circle-notch fa-spin"></i>');
                $('#pesoliquido').html('<i class="fas fa-circle-notch fa-spin"></i>');
                $.ajax({
                    url: "/getProduct/" + $(this).val(),
                }).done(function (data) {
                    if (data.produto_preco_loged_user) {
                        $('#produtoPreco').html(new Intl.NumberFormat('pt-BR', {
                            style: 'currency',
                            currency: 'BRL'
                        }).format(data.produto_preco_loged_user.preco));
                        $('[name="valor"]').valor = data.produto_preco_loged_user.preco;
                    } else {
                        $('#produtoPreco').html('R$ Valor indisponível');
                    }
                    $('[name="nome"]').value = data.nome;
                    $('#pesobruto').html(data.Peso_bruto + ' Kg');
                    $('#pesoliquido').html(data.Peso_liquido + ' Kg');
                });
            } else {
                $('#produtoPreco').html('');
                $('#pesobruto').html('-');
                $('#pesoliquido').html('-');
            }
        }
    );


    if ($("#modalNotification")) {
        $("#modalNotification").modal("show");
    }

    $('[data-toggle="tooltip"]').tooltip();

    $("#categoria_id").change(function () {
        $("#subcategoria_id")
            .find("option")
            .remove()
            .end()
            .append(
                $("<option>", {
                    value: "",
                    text: "Carregando...",
                })
            );

        $.ajax({
            url: "/api/subcategorias/" + $(this).val(),
        }).done(function (data) {
            $("#subcategoria_id").find("option").remove().end();

            console.log(data, data.length);

            if (data.length > 0) {
                $("#subcategoria_id").append(
                    $("<option>", {
                        value: "",
                        text: "Escolha uma Subcategoria",
                    })
                );

                $.each(data, function (i, item) {
                    $("#subcategoria_id").append(
                        $("<option>", {
                            value: item.id,
                            text: item.nome,
                        })
                    );
                });
            } else {
                $("#subcategoria_id").append(
                    $("<option>", {
                        value: "",
                        text: "Categoria não possui Subcategorias",
                    })
                );
            }
        });
    });

    $("#estado_id").change(function () {
        const codigo_uf = $(this).val();

        $("#municipio_id")
            .find("option")
            .remove()
            .end()
            .append(
                $("<option>", {
                    value: "",
                    text: "Carregando...",
                })
            );

        $.ajax({
            url: "/api/municipios/" + codigo_uf,
        }).done(function (data) {
            data = data.results;

            $("#municipio_id").find("option").remove().end();

            if (data.length > 0) {
                $("#municipio_id").append(
                    $("<option>", {
                        value: "",
                        text: "Escolha um Município",
                    })
                );

                $.each(data, function (i, item) {
                    $("#municipio_id").append(
                        $("<option>", {
                            value: item.id,
                            text: item.text,
                        })
                    );
                });
            } else {
                $("#municipio_id").append(
                    $("<option>", {
                        value: "",
                        text: "Estado não possui Municípios",
                    })
                );
            }
        });
    });

    $(".ancora-mais-info").click(function () {
        // a animação vai ocorrer no html, body
        $("html, body").animate(
            {
                // pega o atributo href do this (link que recebeu o click)
                // e faz a animação com velocidade 1000 para o destino do href;
                scrollTop: $($(this).attr("href")).offset().top,
            },
            1000
        );

        // Faz com que o destino do link não vá para a url
        event.preventDefault();
    });

    $(".btn-contato").click(function () {
        $(".btn-contato").removeClass("active");
        $(this).addClass("active");
        $(".departamentos").addClass("d-none");
        $($(this).data("target")).removeClass("d-none");
    });

    $('[data-toggle="popover"]').popover();

    $(".remover").click(function () {
        const target = $($(this).data("target"));
        const quantidade = target.val();

        if (target.val() > 1) {
            target.val(quantidade - 1);
        }
    });

    $(".adicionar").click(function () {
        const target = $($(this).data("target"));
        const quantidade = target.val() * 1;

        target.val(quantidade + 1);
    });

    $(".legenda").change(function () {
        const url = $(this).data("url");
        const legenda = $(this).val();
        const token = $(this).data("csrf");

        const input = $(this);

        $.ajax({
            url: url,
            type: "PUT",
            data: {
                legenda: legenda,
                _token: token,
            },
        }).done(function (data) {
            if (data == "success") {
                input.addClass("is-valid");
            } else {
                input.addClass("is-invalid");
            }
        });
    });

    $(".btn-upload-multiplo-modal").click(function () {
        $("#uploadMultiploModal").modal("show");
        $("#uploadMultiploModal #uploadModalForm").attr(
            "action",
            $(this).attr("data-href")
        );

        if ($(this).attr("data-multiplo")) {
            $("#uploadMultiploModal #uploadModalForm #files").prop(
                "multiple",
                true
            );
        } else {
            $("#uploadMultiploModal #uploadModalForm #files").prop(
                "multiple",
                false
            );
        }

        if ($(this).attr("data-arquivo")) {
            $("#uploadMultiploModal #uploadModalForm #files").attr(
                "data-arquivo",
                1
            );
        } else {
            $("#uploadMultiploModal #uploadModalForm #files").attr(
                "data-arquivo",
                0
            );
        }
    });

    // $('body').delegate(".btn-upload-multiplo-modal", "click", function () {

    // });

    $("#uploadMultiploModal #uploadModalForm").on("submit", function () {
        $("#uploadMultiploModal .modal-body .sec-primary").hide();
        $("#uploadMultiploModal .modal-body .sec-secondary").show();
    });
});

function validarCNPJ(cnpj) {
    cnpj = cnpj.replace(/[^\d]+/g, "");

    if (cnpj == "") return false;

    if (cnpj.length != 14) return false;

    // Elimina CNPJs invalidos conhecidos
    if (
        cnpj == "00000000000000" ||
        cnpj == "11111111111111" ||
        cnpj == "22222222222222" ||
        cnpj == "33333333333333" ||
        cnpj == "44444444444444" ||
        cnpj == "55555555555555" ||
        cnpj == "66666666666666" ||
        cnpj == "77777777777777" ||
        cnpj == "88888888888888" ||
        cnpj == "99999999999999"
    )
        return false;

    // Valida DVs
    tamanho = cnpj.length - 2;
    numeros = cnpj.substring(0, tamanho);
    digitos = cnpj.substring(tamanho);
    soma = 0;
    pos = tamanho - 7;
    for (i = tamanho; i >= 1; i--) {
        soma += numeros.charAt(tamanho - i) * pos--;
        if (pos < 2) pos = 9;
    }
    resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
    if (resultado != digitos.charAt(0)) return false;

    tamanho = tamanho + 1;
    numeros = cnpj.substring(0, tamanho);
    soma = 0;
    pos = tamanho - 7;
    for (i = tamanho; i >= 1; i--) {
        soma += numeros.charAt(tamanho - i) * pos--;
        if (pos < 2) pos = 9;
    }
    resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
    if (resultado != digitos.charAt(1)) return false;

    return true;
}

window.checkCnpj = function (input) {
    if (!validarCNPJ(input.value)) {
        input.value = "";
        alert("CNPJ invalido.");
    }
};
