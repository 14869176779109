require("select2");

$(function () {
    $(".select2").select2({
        theme: "bootstrap",
    });

    $(".select2-cidades").select2({
        ajax: {
            url: "/api/municipios",
            dataType: "json",
            data: function (params) {
                var query = {
                    search: params.term,
                };

                // Query parameters will be ?search=[term]&type=public
                return query;
            },
        },
        theme: "bootstrap",
    });

    $(".select2-estados").attr("translate", "no");
    $(".select2-estados").select2({

        ajax: {
            url: "/api/estados",
            dataType: "json",
            data: function (params) {
                var query = {
                    search: params.term,
                };

                // Query parameters will be ?search=[term]&type=public
                return query;
            },
        },
        theme: "bootstrap",
    });

    $(".select2-estados").on("change", function () {
        $(".select2-cidades").removeAttr("disabled");

        $(".select2-cidades").select2({
            ajax: {
                url:
                    "/api/municipios/" +
                    $(".select2-estados option:selected").val(),

                dataType: "json",
                data: function (params) {
                    var query = {
                        search: params.term,
                    };

                    // Query parameters will be ?search=[term]&type=public
                    return query;
                },
            },
            theme: "bootstrap",
        });
    });

    if (document.querySelector("#cep")) {
        $(".select2-cidades").on("change", function () {
            var municipio = $(".select2-cidades option:selected").text();
        });
    }
});
