const { getElement } = require("dropzone");
const { lowerCase, trim, upperCase } = require("lodash");

function normalize(phone) {
    //normalize string and remove all unnecessary characters
    phone = phone.replace(/[^\d]/g, "");

    //check if number length equals to 10
    if (phone.length == 11) {
        //reformat and return phone number
        return phone.replace(/(\d{2})(\d{5})(\d{4})/, "($1) $2-$3");
    } else if (phone.length == 10) {
        return phone.replace(/(\d{2})(\d{4})(\d{4})/, "($1) $2-$3");
    }

    return null;
}

(function () {
    var states = document.getElementsByClassName("estado")

    $(".filtro-onde-encontrar-estado").change(function () {
        const token = $(this).data("csrf");
        const estado = $('#estado_id').val();
        // const municipio = $('#municipio_id').val();

        $('.estado').removeClass('active');
        $('#estado-' + estado).addClass('active');

        $.ajax({
            url: '/get-onde-encontrar',
            type: "GET",
            data: {
                estado_id: estado,
                municipio_id: null,
                _token: token,
            },
        }).done(function (response) {
            getBody(response, estado)

            var municipio = $('#municipio_id').find('option[value=""]').text();
            $('#select2-municipio_id-container').html(trim(municipio))
        });
    });

    $(".filtro-onde-encontrar-municipio").change(function () {
        const token = $(this).data("csrf");
        const estado = $('#estado_id').val();
        const municipio = $('#municipio_id').val();

        $('.estado').removeClass('active');

        $.ajax({
            url: '/get-onde-encontrar',
            type: "GET",
            data: {
                estado_id: null,
                municipio_id: municipio,
                _token: token,
            },
        }).done(function (response) {
            getBody(response, estado.val)
        });

        $.ajax({
            url: '/onde-encontrar-municipio',
            type: "GET",
            data: {
                municipio_id: municipio,
                _token: token,
            },
        }).done(function (response) {
            $('#estado-' + response).addClass('active');

            var text = $('#estado_id').find('option[value="' + response + '"]').text();

            $('#select2-estado_id-container').html(trim(text))
        });
    });

    for (var i = 0; i < states.length; i++) {
        states[i].onclick = function () {
            const code = this.getAttribute('code');
            const token = $(this).data("csrf");

            $('.estado').removeClass('active');
            this.classList.add("active");

            $(".estado-container").prop('hidden', true);

            $("#" + code).prop('hidden', false);

            var municipio = $('#municipio_id').find('option[value=""]').text();
            $('#select2-municipio_id-container').html(trim(municipio))

            $.ajax({
                url: '/get-onde-encontrar',
                type: "GET",
                data: {
                    estado_id: code,
                    municipio_id: null,
                    _token: token,
                },
            }).done(function (response) {
                getBody(response, code)
            });
        }
    }

    function getBody(response, code) {
        var body = '';

        response.forEach(franquia => {
            body = body + '<tr> <td class="capitalize" translate="no">' + lowerCase(franquia['nome']) + '</td> <td class="capitalize" translate="no">' + lowerCase(franquia['classificacao']) + '</td> <td class="capitalize" translate="no"> ' + lowerCase(franquia['endereco']) + ', ' + lowerCase(franquia['numero']) + ' - ' + lowerCase(franquia['bairro']) + ' <br> ' + lowerCase(franquia['cep']) + ' - ' + lowerCase(franquia['municipio']) + ' - ' + upperCase(franquia['estado']) + ' </td> <td translate="no"> <i class="fa fa-phone"></i> <span class="telefone">' + normalize(franquia['telefone']) + '</span><br> '
            if (franquia['email']) {
                body = body + '<i class="fa fa-envelope"></i> ' + franquia['email']
            }
            body = body + '</td> </tr>'
        });

        if (response.length <= 0) {
            body = '<tr> <th scope="row" class="text-center" colspan="3"> Nenhum registro encontrado!</th> </tr>';
        }

        var html = '<thead> <tr> <th class="col-2" scope="col">Nome</th> <th class="col-1" scope="col">Classificação</th> <th class="col-6" scope="col">Endereço</th> <th class="col-3" scope="col">Contatos</th> </tr>  <tbody> ' + body + ' </tbody>';

        $('.table-franquias').html(html)

        $('#estado_id').find('option[value="' + code + '"]').attr('selected', 'selected');

        if (code === undefined) {
            var text = $('#estado_id').find('option[value=""]').text();
        } else {
            var text = $('#estado_id').find('option[value="' + code + '"]').text();
        }

        $('#select2-estado_id-container').html(trim(text))

    }
})();


