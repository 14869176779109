import { tns } from "tiny-slider/src/tiny-slider.module"


if (document.querySelector('.slide-wrapper')) {

    var slider = tns({
        container: ".slide-wrapper",
        items: 1,
        responsive: {
            0: {
                items: 1.5,
                gutter: 10,
            },
            900: {
                items: 4,
                gutter: 10,
            }
        },
        autoplay: true,
        autoplayTimeout: 8000,
        mouseDrag: true,
        loop: true,
        autoplayHoverPause: true,
        autoplayButtonOutput: false,
        nav: false,
        autoplayButton: false,
        controls: false
    });


    if (document.querySelector('.prev')) {
        document.querySelector('.prev').onclick = function () {
            slider.goTo('prev');
        };
    }

    if (document.querySelector('.next')) {

        document.querySelector('.next').onclick = function () {
            slider.goTo('next');
        };
    }

}


if (document.querySelector('.slide-wrapper-categorias')) {

    var sliderCategoria = tns({
        container: ".slide-wrapper-categorias",
        responsive: {
            0: {
                items: 1
            },
            900: {
                items: 2
            }
        },
        slideBy: "page",
        autoplay: false,
        mouseDrag: true,
        nav: false,
        autoplayButton: false,
        controls: false
    });

    if (document.querySelector('.prev-wrapper-categoria')) {
        document.querySelector('.prev-wrapper-categoria').onclick = function () {
            sliderCategoria.goTo('prev');
        };
    }

    if (document.querySelector('.next-wrapper-categoria')) {
        document.querySelector('.next-wrapper-categoria').onclick = function () {
            sliderCategoria.goTo('next');
        };
    }
}

if (document.querySelector('.slide-wrapper-novidades')) {

    var slidernovidade = tns({
        container: ".slide-wrapper-novidades",
        items: 1,
        responsive: {
            0: {
                items: 1.5,
                gutter: 10,
            },
            900: {
                items: 4,
                gutter: 10,
            }
        },
        autoplay: true,
        autoplayTimeout: 8000,
        mouseDrag: true,
        loop: true,
        autoplayHoverPause: true,
        autoplayButtonOutput: false,
        nav: false,
        autoplayButton: false,
        controls: false
    });

    if (document.querySelector('.prev-wrapper-novidade')) {
        document.querySelector('.prev-wrapper-novidade').onclick = function () {
            slidernovidade.goTo('prev');
        };
    }

    if (document.querySelector('.next-wrapper-novidade')) {
        document.querySelector('.next-wrapper-novidade').onclick = function () {
            slidernovidade.goTo('next');
        };
    }

}

if (document.querySelector('.slide-wrapper-produtos')) {

    var sliderproduto = tns({
        container: ".slide-wrapper-produtos",
        items: 1,
        responsive: {
            0: {
                items: 1.5,
                gutter: 10,
            },
            900: {
                items: 4,
                gutter: 10,
            }
        },
        autoplay: true,
        autoplayTimeout: 8000,
        mouseDrag: true,
        loop: true,
        autoplayHoverPause: true,
        autoplayButtonOutput: false,
        nav: false,
        autoplayButton: false,
        controls: false
    });

    if (document.querySelector('.prev-wrapper-produto')) {
        document.querySelector('.prev-wrapper-produto').onclick = function () {
            sliderproduto2.goTo('prev');
            sliderproduto.goTo('prev');
        };
    }

    if (document.querySelector('.next-wrapper-produto')) {
        document.querySelector('.next-wrapper-produto').onclick = function () {
            sliderproduto2.goTo('next');
            sliderproduto.goTo('next');
        };
    }

}

if (document.querySelector('.slide-wrapper-produtos2')) {

    var sliderproduto2 = tns({
        container: ".slide-wrapper-produtos2",
        items: 1,
        responsive: {
            0: {
                items: 1.5,
                gutter: 10,
            },
            900: {
                items: 4,
                gutter: 10,
            }
        },
        autoplay: true,
        autoplayTimeout: 8000,
        mouseDrag: true,
        loop: true,
        autoplayHoverPause: true,
        autoplayButtonOutput: false,
        nav: false,
        autoplayButton: false,
        controls: false
    });

    if (document.querySelector('.prev-wrapper-produto')) {
        document.querySelector('.prev-wrapper-produto').onclick = function () {
            sliderproduto2.goTo('prev');
            sliderproduto.goTo('prev');
        };
    }

    if (document.querySelector('.next-wrapper-produto')) {
        document.querySelector('.next-wrapper-produto').onclick = function () {
            sliderproduto2.goTo('next');
            sliderproduto.goTo('next');
        };
    }

}

if (document.querySelector('.slide-wrapper-blogs')) {

    var sliderblog = tns({
        container: ".slide-wrapper-blogs",
        items: 1,
        responsive: {
            0: {
                items: 1.5,
                gutter: 10,
            },
            900: {
                items: 4,
                gutter: 10,
            }
        },
        autoplay: true,
        autoplayTimeout: 8000,
        mouseDrag: true,
        loop: true,
        autoplayHoverPause: true,
        autoplayButtonOutput: false,
        nav: false,
        autoplayButton: false,
        controls: false
    });

    if (document.querySelector('.prev-wrapper-blog')) {
        document.querySelector('.prev-wrapper-blog').onclick = function () {
            sliderblog.goTo('prev');
        };
    }

    if (document.querySelector('.next-wrapper-blog')) {
        document.querySelector('.next-wrapper-blog').onclick = function () {
            sliderblog.goTo('next');
        };
    }

}

if (document.querySelector('.slide-wrapper-mini-banners')) {

    var sliderMiniBanner = tns({
        container: ".slide-wrapper-mini-banners",
        items: 1,
        responsive: {
            0: {
                items: 1.5,
                gutter: 10,
            },
            900: {
                items: 3,
                gutter: 10,
            }
        },
        autoplay: true,
        autoplayTimeout: 8000,
        mouseDrag: true,
        loop: true,
        autoplayHoverPause: true,
        autoplayButtonOutput: false,
        nav: false,
        autoplayButton: false,
        controls: false
    });

    if (document.querySelector('.prev-wrapper-mini-banner')) {
        document.querySelector('.prev-wrapper-mini-banner').onclick = function () {
            sliderMiniBanner.goTo('prev');
        };
    }

    if (document.querySelector('.next-wrapper-mini-banner')) {
        document.querySelector('.next-wrapper-mini-banner').onclick = function () {
            sliderMiniBanner.goTo('next');
        };
    }

}


if (document.querySelector('.slide-wrapper-galeria-produto')) {

    var sliderGeleriaProduto = tns({
        container: ".slide-wrapper-galeria-produto",
        items: 1,
        slideBy: "page",
        autoplay: true,
        autoplayTimeout: 8000,
        autoplayButtonOutput: false,
        mouseDrag: false,
        nav: false,
        autoplayButton: false,
        controls: false
    });

    if (document.querySelector('.slide-wrapper-galeria-produto2')) {

        var sliderGeleriaProduto2 = tns({
            container: ".slide-wrapper-galeria-produto2",
            responsive: {
                0: {
                    items: 2
                },
                900: {
                    items: 3
                }
            },
            slideBy: 1,
            autoplay: false,
            mouseDrag: true,
            nav: false,
            autoplayButton: false,
            controls: false
        });

        $("#tns2-item0").addClass("d-none");

        if (document.querySelector('.prev-galeria-produto')) {
            document.querySelector('.prev-galeria-produto').onclick = function () {
                $('.tns-item').removeClass('d-none');

                sliderGeleriaProduto.goTo('prev');
                sliderGeleriaProduto2.goTo('prev');
                $('.slide-wrapper-galeria-produto2 .container-imagem-galeria').removeClass('active');
                $('.slide-wrapper-galeria-produto2 .container-imagem-galeria').each(function (index) {
                    if ($(this).data('item') == sliderGeleriaProduto.getInfo().displayIndex - 1) {
                        $(this).addClass('active');

                        $(this).parent().addClass('d-none');
                    }
                });
            };
        }

        if (document.querySelector('.next-galeria-produto')) {
            document.querySelector('.next-galeria-produto').onclick = function () {
                $('.tns-item').removeClass('d-none');

                sliderGeleriaProduto.goTo('next');
                sliderGeleriaProduto2.goTo('next');
                $('.slide-wrapper-galeria-produto2 .container-imagem-galeria').removeClass('active');
                $('.slide-wrapper-galeria-produto2 .container-imagem-galeria').each(function (index) {
                    if ($(this).data('item') == sliderGeleriaProduto.getInfo().displayIndex - 1) {
                        $(this).addClass('active');

                        $(this).parent().addClass('d-none');
                    }
                });
            };
        }

        $('.slide-wrapper-galeria-produto2 .container-imagem-galeria').click(function () {
            $('.tns-item').removeClass('d-none');

            sliderGeleriaProduto.goTo($(this).data('item'));
            $('.container-imagem-galeria').removeClass('active');
            $(this).addClass('active');
            $('#imagem-destaque').attr('src', $(this).data('url'));

            $(this).parent().addClass('d-none');
        });
    }
}

var isMobile = navigator.userAgent.match(/(iPad)|(iPhone)|(iPod)|(android)|(webOS)/i)


if (document.querySelector('.slide-wrapper-categoria')) {

    var sliderCategoria = tns({
        container: ".slide-wrapper-categoria",
        items: 1,
        responsive: {
            0: {
                items: 1,
                gutter: 5,
            },
            300: {
                items: 3,
                gutter: 5,
            },
            400: {
                items: 3,
                gutter: 5,
            },
            500: {
                items: 4,
                gutter: 5,
            },
            600: {
                items: 5,
                gutter: 5,
            },
            800: {
                items: 6,
                gutter: 5,
            },
            900: {
                items: 7,
                gutter: 5,
            },
            1000: {
                items: 8,
                gutter: 5,
            },
            1100: {
                items: 9,
                gutter: 5,
            },
            1200: {
                items: 9,
                gutter: 5,
            }
        },
        lazyload: true,
        autoplay: true,
        autoplayTimeout: 8000,
        mouseDrag: true,
        loop: true,
        autoplayHoverPause: true,
        autoplayButtonOutput: false,
        nav: isMobile ? true : false,
        navPosition: 'bottom',
        autoplayButton: false,
        controls: false
    });

    if (document.querySelector('.prev-wrapper-categoria')) {
        document.querySelector('.prev-wrapper-categoria').onclick = function () {
            sliderCategoria.goTo('prev');
        };
    }

    if (document.querySelector('.next-wrapper-categoria')) {
        document.querySelector('.next-wrapper-categoria').onclick = function () {
            sliderCategoria.goTo('next');
        };
    }
}
