import 'owl.carousel'

$(function () {

    $('.owl-carousel-categorias').owlCarousel({
        loop: true,
        margin: 10,
        autoplay: true,
        autoplayTimeout: 5000,
        responsiveClass: true,
        responsive: {
            0: {
                items: 1,
                nav: false,
            },
            300: {
                items: 3,
                nav: false,
            },
            400: {
                items: 3,
                nav: false,
            },
            500: {
                items: 4,
                nav: false,
            },
            600: {
                items: 5,
                nav: true,
            },
            800: {
                items: 6,
                nav: true,
            },
            900: {
                items: 7,
                nav: true,
            },
            1000: {
                items: 8,
                nav: true,
            },
            1100: {
                items: 9,
                nav: true,
            },
            1200: {
                items: 9,
                nav: true,
            }
        }
    })

    $('.owl-carousel-novidades').owlCarousel({
        loop: true,
        margin: 10,
        autoplay: true,
        autoplayTimeout: 8000,
        responsiveClass: true,
        responsive: {
            0: {
                items: 1.5,
                nav: false,
            },
            900: {
                items: 4,
                nav: true,
            }
        }
    })

    $('.owl-carousel-produtos').owlCarousel({
        loop: true,
        margin: 10,
        autoplay: true,
        autoplayTimeout: 8000,
        responsiveClass: true,
        responsive: {
            0: {
                items: 1.5,
                nav: false,
            },
            900: {
                items: 4,
                nav: true,
            }
        }
    })

    $('.owl-carousel-produtos2').owlCarousel({
        loop: true,
        margin: 10,
        autoplay: true,
        autoplayTimeout: 8000,
        responsiveClass: true,
        responsive: {
            0: {
                items: 1.5,
                nav: false,
            },
            900: {
                items: 4,
                nav: true,
            }
        }
    })

    $('.owl-carousel-blogs').owlCarousel({
        loop: true,
        margin: 10,
        autoplay: true,
        autoplayTimeout: 8000,
        responsiveClass: true,
        nav: true,
        responsive: {
            0: {
                items: 1.5,
                nav: false,
            },
            900: {
                items: 4,
            }
        }
    })

    $('.owl-carousel-mini-banner').owlCarousel({
        loop: true,
        margin: 10,
        autoplay: true,
        autoplayTimeout: 5000,
        responsiveClass: true,
        responsive: {
            0: {
                items: 1.5,
                nav: false,
            },
            900: {
                items: 3,
                nav: true,
            }
        }
    })

    $('.owl-carousel-related').owlCarousel({
        loop: true,
        margin: 10,
        autoplay: true,
        autoplayTimeout: 5000,
        responsiveClass: true,
        responsive: {
            0: {
                items: 1.5,
                nav: false,
            },
            900: {
                items: 4,
                nav: true,
            }
        }
    })
})
